function Get(url){
  var Httpreq = new XMLHttpRequest();
  Httpreq.open("GET",url,false);
  Httpreq.send(null);
  return Httpreq.responseText;
}

function BookButtons(el) {
  const id = el.dataset.instance;
  const seats = JSON.parse(Get(`https://tickets.thetungauditorium.com/thetungauditorium/api/v3/instances/${id}/status?includechildplans=true`));
  const showRemaining = el.dataset.showRemaining;

  if (seats !== null) {
    let availability = seats.available;
    let capacity = seats.capacity;

    
    if (availability === 0) {
      el.innerHTML = "Sold out";
      el.href = "javascript:void();"
    } else if (showRemaining) {

      let availabilityEl = document.createElement("SPAN");
      availabilityEl.classList.add("text-sm");

      if (availability < capacity / 10) {
        availabilityEl.innerHTML = `${availability} remaining`;
      }
      el.after(availabilityEl);
    }


  }

}

export function bindBookButtons() {
  return [...document.querySelectorAll("[data-book-button]")].map((el) => BookButtons(el));
}