// Get querystring
function Newsletter(el) {
  const urlParams = new URLSearchParams(window.location.search);
  const error = urlParams.get('Error');
  const hash = window.location.hash;
  const uri = window.location.pathname;
  const thanksEl = document.querySelector("[data-newsletter-thanks]");
  let errorText;

  // If exists, handle error
  if(error) {

    switch(error) {
      case "NoEmail":
        errorText = "You didn't enter an email address."
        break;
      case "NoLastName":
        errorText = "You didn't enter an last name."
        break;
      case "InvalidEmail":
        errorText = "The email address you entered was invalid."
        break;
      case "EmailAddressTooLong":
        errorText = "The email address you entered was more than 255 characters."
        break;
      case "FirstNameTooLong":
        errorText = "The first name you entered was more than 30 characters."
        break;
      case "LastNameTooLong":
        errorText = "The last name you entered was more than 80 characters."
        break;
      default:
        errorText = "Something went wrong."
    }

    let errorEl = document.createElement("P");
    errorEl.classList.add("text-orange-darkest","font-bold", "mb-4");
    errorEl.innerHTML = errorText + " Please try again."

    el.prepend(errorEl);

  } else if(uri === "/newsletter-thanks") {
    el.classList.add("hidden");
    thanksEl.classList.remove("hidden");
  }

}

export function bindNewsletter() {
  return [...document.querySelectorAll("[data-newsletter]")].map((el) => Newsletter(el));
}