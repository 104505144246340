import { bindToggles } from "./toggle";
import { bindBookButtons } from "./book-button";
import { bindCookieConsent } from "./cookie-consent";
import { bindNewsletter } from "./newsletter";

bindToggles();
bindBookButtons();
bindCookieConsent();
bindNewsletter();

export default {
//   bindToggles();
};
