// Vendor

// Assets
import './css/index.css';
import 'virtual:svg-icons-register';

// // Bootstrap
import setLoaded from './js/setLoaded';
import initModules from './js/initModules';
import modulesEnabled from './js/modulesEnabled';


// Vue apps
const { APP } = window;
APP.DEBUG = typeof APP.DEBUG !== 'undefined' ? APP.DEBUG : !process.env.NODE_ENV === 'production';

window.addEventListener('DOMContentLoaded', () => {
  setLoaded(APP);
  APP.modules = initModules(APP.modules, modulesEnabled, APP.DEBUG);
});
