// Create cookie
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// Delete cookie
function deleteCookie(cname) {
  const d = new Date();
  d.setTime(d.getTime() + (24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=;" + expires + ";path=/";
}

// Read cookie
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

// Set cookie consent
function acceptCookieConsent() {
  deleteCookie('cookie_consent');
  setCookie('cookie_consent', 1, 30);
  document.querySelector("[data-cookie-notice]").remove();;
}

function CookieConsent(el) {
  const cookie_consent = getCookie("cookie_consent");
  const acceptButton = el.querySelector("[data-cookie-accept]");

  if (cookie_consent != ""){
      el.remove();
  }

  acceptButton.addEventListener("click", function(e) {
    acceptCookieConsent();
  });
}

export function bindCookieConsent() {
  return [...document.querySelectorAll("[data-cookie-notice]")].map((el) => CookieConsent(el));
}
