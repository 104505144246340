function toggle(el, targetEl, toggleFixed) {
  el.classList.toggle("active");
  targetEl.classList.toggle("hidden");
  if (toggleFixed) {
    document.querySelector("body").classList.toggle("overflow-hidden");
  }
}

function hide(targetEl) {
  targetEl.classList.add("hidden");
}

function Toggler(el) {
  const targetId = el.getAttribute("aria-controls");
  const targetEl = document.getElementById(targetId);
  const toggleFixed = el.hasAttribute("data-toggle-fixed");

  hide(targetEl);

  el.addEventListener("click", function(e) {
    if (el.tagName === "A") {
      e.preventDefault();
    }

    toggle(this, targetEl, toggleFixed);
  });
}

export function bindToggles() {
  return [...document.querySelectorAll("[data-toggle]")].map((el) => Toggler(el));
}
